import imageCompression from 'browser-image-compression';
import currency from 'currency.js'

const moment = require('moment');
const xml2js = require('xml2js');
const crypto = require('crypto');

const algorithm = 'aes-256-ctr';
const ENCRYPTION_KEY = Buffer.from('FoCKvdLslUuB4y3EZlKate7XGottHski1LmyqJHvUhs=', 'base64');
const IV_LENGTH = 16;


export const havePermission = (props, permission) => {
  if( !props ) {
    return false;
  }
  if( !props.permission ) {
    return false;
  }
  return (props.permission[permission] || props.permission['ALL'] || props.role.findIndex((x) => x == 'adm' || x == 'mas') !== -1) ? true : false;
}

export const haveRole = (props, role) => {
  if (!props) {
    return false;
  }
  return props.findIndex((x) => x == role || x == 'adm' || x == 'mas') !== -1 ? true : false;
}

export const mask = (value, pattern) => {
  if( !value ) {
    return '';
  }

  let i = 0;
  const v = value.toString();

  return pattern.replace(/#/g, () => v[i++] || '')
}

export const formatNumber = (value, fixed = 2) => {
  value = decimalWithoutRounding(value, 6);
  value = String(value).replace('.', ',').replace(/\d(?=(\d{3})+\,)/g, '$&.');

  return currency(
    value,
    { precision: fixed, symbol: '', decimal: ',', separator: '.' }
  ).format()
}

export const resizeFile = async (file) => {

  const imageFile = file;

  const options = {
    maxSizeMB: 0.60,
    maxWidthOrHeight: 768,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    const base64 = imageCompression.getDataUrlFromFile(compressedFile);

    return base64;
  } catch (error) {
    console.log(error);
  }

}

export const resizeFileToHighQuality = async (file) => {

  const imageFile = file;

  const options = {
    maxSizeMB: 1.50,
    maxWidthOrHeight: 2480,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    const base64 = imageCompression.getDataUrlFromFile(compressedFile);

    return base64;
  } catch (error) {
    console.log(error);
  }

}

// Função para comprimir a imagem
export const resizeImage = (imageData) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageData;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calcula as dimensões mantendo a proporção
      let width = img.width;
      let height = img.height;
      const maxDimension = 1024;

      if (width > height) {
        if (width > maxDimension) {
          height = Math.round((height * maxDimension) / width);
          width = maxDimension;
        }
      } else {
        if (height > maxDimension) {
          width = Math.round((width * maxDimension) / height);
          height = maxDimension;
        }
      }

      canvas.width = width;
      canvas.height = height;

      // Configurações para melhor qualidade
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Desenha a imagem
      ctx.drawImage(img, 0, 0, width, height);

      // Converte para base64
      const resizedImage = canvas.toDataURL('image/jpeg', 1.0);
      resolve(resizedImage);
    };
  });
};


export const convertToFloatNumber = (value) => {
  value = value.toString();
  if (value.indexOf('.') !== -1 || value.indexOf(',') !== -1) {
    return parseFloat(value.replace(/\./g, '').replace(/,/gi, '.'));
  } else {
    return parseFloat(value);
  }
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export const getXml = (file, cb) => {
  let reader = new FileReader();
  reader.readAsText(file);
  reader.onloadend = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export const getDataNfeJson = (nfe, cb) => {
  try {

    let formatData = {};

    nfe.nfeProc.NFe.map((item1) => {
      item1.infNFe.map((item2) => {
        item2.ide.map((item11) => {
          formatData.purchaseDate = item11.dhEmi[0].slice(0, 10);
          formatData.numberNfe = item11.nNF[0];
        })

        formatData.company = {}
        const emit = item2.emit.map((item12) => {
          formatData.company.name = item12.xNome[0];
          formatData.company.cnpj = item12.CNPJ[0];
          formatData.company.phone = item12.enderEmit[0] && item12.enderEmit[0].fone && item12.enderEmit[0].fone[0] ? item12.enderEmit[0].fone[0] : "";
          formatData.company.address = {};
          formatData.company.address.city = item12.enderEmit[0] && item12.enderEmit[0].xMun && item12.enderEmit[0].xMun[0] ? item12.enderEmit[0].xMun[0] : "";
          formatData.company.address.uf = item12.enderEmit[0] && item12.enderEmit[0].UF && item12.enderEmit[0].UF[0] ? item12.enderEmit[0].UF[0] : "";
          formatData.company.address.cep = item12.enderEmit[0] && item12.enderEmit[0].CEP && item12.enderEmit[0].CEP[0] ? item12.enderEmit[0].CEP[0] : "";
        })

        Promise.all([Promise.all(emit)]).then(() => {
          formatData.items = [];
          item2.det.map((item3) => {
            item3.prod.map((item4) => {
              formatData.items.push({
                identityNumber: item3.$.nItem,
                ref: item4.cProd[0],
                description: item4.xProd[0],
                initials: item4.uCom[0],
                originalQuantity: parseFloat(item4.qCom[0]),
                quantity: parseFloat(item4.qCom[0]),
                unityCost: item4.vDesc ? parseFloat(item4.vUnCom[0]) - (parseFloat(item4.vDesc[0]) / parseFloat(item4.qCom[0])) : parseFloat(item4.vUnCom[0]),
                type: "cpfCnpj:" + formatData.company.cnpj
              })
            })
          })
          formatData.invoiceValue = parseFloat(item2.total[0].ICMSTot[0].vNF[0]);
        });

      })

    });

    nfe.nfeProc.protNFe.map((item2) => {
      item2.infProt.map((item12) => {
          formatData.accessKey = item12.chNFe[0]
      })
    });

    cb(formatData);

  } catch (error) {
    console.log(error);
  }
}

export const toDataURL = (url, callback) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
  xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET');
  xhr.setRequestHeader('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers');
  xhr.responseType = 'blob';
  xhr.send();
}

export const getBase64ImageFromUrl = async (url, callback) => {
  url += "?" + (new Date()).getTime();

  var res = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Access-Control-Allow-Origin': '*'
    })
  });
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}

export const decimalWithoutRounding = (number = 0, fixed = 2) => {
  number = number ? number.toString() : "0";
  if( number.indexOf(".") !== -1 ) {
    number = number.slice(0, (number.indexOf(".")) + 1 + Number(fixed));
  }
  return Number(number)
}

export const getDaysArray = function (year, month) {
  var monthIndex = month - 1; // 0..11 instead of 1..12
  var names = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
  var date = new Date(year, monthIndex, 1);
  var result = [];
  while (date.getMonth() == monthIndex) {
    //result.push(date.getDate() + '-' + names[date.getDay()]);
    result.push(moment(date).format('DD/MM/YYYY'));
    date.setDate(date.getDate() + 1);
  }
  return result;
}

export const conversionFloatInTime = function(number = 0) {
  // Check sign of given number
  let sign = (number >= 0) ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + '';

  // Add padding if need
  if (minute.length < 2) {
    minute = '0' + minute;
  }

  // Add Sign in final result
  sign = sign == 1 ? '' : '-';

  // Concate hours and minutes
  let time = sign + String(hour).padStart(2, "0") + ':' + String(minute).padStart(2, "0");

  return time;
}

export const conversionTimeInFloat = function (time = "00:00") {
  console.log(time);
  // Number of decimal places to round to
  var decimal_places = 2;

  // Maximum number of hours before we should assume minutes were intended. Set to 0 to remove the maximum.
  var maximum_hours = 15;

  // 3
  var int_format = time.match(/^\d+$/);

  // 1:15
  var time_format = time.match(/([\d]*):([\d]+)/);

  // 10m
  var minute_string_format = time.toLowerCase().match(/([\d]+)m/);

  // 2h
  var hour_string_format = time.toLowerCase().match(/([\d]+)h/);

  if (time_format != null) {
    var hours = parseInt(time_format[1]);
    var minutes = parseFloat(time_format[2] / 60);
    time = hours + minutes;
  } else if (minute_string_format != null || hour_string_format != null) {
    if (hour_string_format != null) {
      var hours = parseInt(hour_string_format[1]);
    } else {
      var hours = 0;
    }
    if (minute_string_format != null) {
      var minutes = parseFloat(minute_string_format[1] / 60);
    } else {
      var minutes = 0;
    }
    time = hours + minutes;
  } else if (int_format != null) {
    // Entries over 15 hours are likely intended to be minutes.
    time = parseInt(time);
    if (maximum_hours > 0 && time > maximum_hours) {
      time = (time / 60).toFixed(decimal_places);
    }
  }

  // make sure what ever we return is a 2 digit float
  time = time.toFixed(decimal_places);
  return parseFloat(time);
}

export const generateColors = (qtd) => {
  let mycolors = ["#F26D6E", "#FF92BF", "#8B79DD", "#2C97DF", "#08D2FF", "#90E3CF", "#64D472", "#ADD362", "#ED7F64", "#E99E5B", "#FCE970", "#CEC0B7"];

  if ( qtd < mycolors.length ) {
    while( mycolors.length != qtd) {
        mycolors.pop();
    }
    return mycolors;
  }
  if (qtd == mycolors.length ) {
      return mycolors;
  }
  if ( qtd > mycolors.length ) {
    while ( mycolors.length != qtd) {

      var newcolor = '#' + Math.random().toString(16).substring(2, 8);
      mycolors.push(newcolor);

      mycolors.filter(( ele, pos) => mycolors.indexOf(ele) == pos);

    }
    return mycolors;
  }
  else {
    return [];
  }
}

export const hourlyEmployeeCost = (wage) => {
  let hourlyPay = ((wage / 30) / 7.333333333);
  let remuneração = (
    (7.333333333 * 275 * hourlyPay) +
    (48 * 7.333333333 * hourlyPay) +
    (220 * hourlyPay) +
    ((1 / 3) * wage) +
    (12 * 7.333333333 * hourlyPay) +
    (220 * hourlyPay)

  );
  let encSociais = (remuneração * 0.08) + (remuneração * 0.278) + 3600;
  let result = (encSociais + remuneração) / (7.333333333 * 275);

  let nighhour = (
    (((7.333333333 * 275 * hourlyPay) * 1.14) * 0.2) +
    (7.333333333 * 275 * hourlyPay) +
    (48 * 7.333333333 * hourlyPay) +
    (220 * hourlyPay) +
    ((1 / 3) * wage) +
    (12 * 7.333333333 * hourlyPay) +
    (220 * hourlyPay)

  );
  let nighhourresult = (nighhour + encSociais) / (7.333333333 * 275);


  return {
    daytime: Number(result.toFixed(2)),
    nightly: Number(nighhourresult.toFixed(2))
  };
}

export const getXmlAsJson = async (file, callback) => {
  const parser = new xml2js.Parser();
  parser.parseStringPromise(file)
  .then(function(resp) {
      callback(resp)
  }).catch(function(err) {
      //callback({ "err": err })
  })
}

export const setJsonLocalStorage = (local, data) => {
  try {

    let iv = crypto.randomBytes(IV_LENGTH);
    let cipher = crypto.createCipheriv(algorithm, Buffer.from(ENCRYPTION_KEY, 'hex'), iv);
    let encrypted = cipher.update(JSON.stringify(data));
    encrypted = Buffer.concat([encrypted, cipher.final()]);

    localStorage.setItem(local, iv.toString('hex') + ':' + encrypted.toString('hex'));

  } catch (error) {
    //console.log(error);
  }
}

export const getJsonLocalStorage = (local) => {
  try {

    var hash = localStorage.getItem(local);

    if( hash && hash !== null && hash !== undefined ) {

      let textParts = hash.split(':');
      let iv = Buffer.from(textParts.shift(), 'hex');
      if( iv ) {
        let encryptedText = Buffer.from(textParts.join(':'), 'hex');
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(ENCRYPTION_KEY, 'hex'), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);

        return JSON.parse(decrypted.toString());
      }

    }

    return {};

  } catch (error) {
    localStorage.clear();
    //console.log(error);
  }
}

export const generateString = () => {
  let timestamp = Date.now() / 1000;
  return timestamp + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export const ourMult = (num1 = 0, num2 = 0) => {

  // Definir o número máximo de casas decimais
  const maxDecimals = Math.max(countDecimals(num1), countDecimals(num2));

  // Multiplicar os números pelo fator adequado
  const multiplier = 10 ** maxDecimals;

  // Multiplicar e somar os números
  const result = (num1 * multiplier * num2 * multiplier) / (multiplier * multiplier);

  return decimalWithoutRounding2(result, 9);

}

export const ourSum = (num1 = 0, num2 = 0) => {

  // Definir o número máximo de casas decimais
  const maxDecimals = Math.max(countDecimals(num1), countDecimals(num2));

  // Multiplicar os números pelo fator adequado
  const multiplier = 10 ** maxDecimals;

  // Multiplicar e somar os números
  const result = (num1 * multiplier + num2 * multiplier) / multiplier;

  return decimalWithoutRounding2(result, 9);
}

export const ourDiv = (num1 = 0, num2 = 1) => {

  // Definir o número máximo de casas decimais
  const maxDecimals = Math.max(countDecimals(num1), countDecimals(num2));

  // Multiplicar os números pelo fator adequado
  const multiplier = 10 ** maxDecimals;

  // Ajustar num1 e num2 de acordo com o fator
  const adjustedNum1 = num1 * multiplier;
  const adjustedNum2 = num2 * multiplier;

  // Evitar divisão por zero
  if (adjustedNum2 === 0) {
    return 0;
  }

  // Dividir os números ajustados e ajustar a precisão
  const result = (adjustedNum1 / adjustedNum2);

  return decimalWithoutRounding2(result, 9);
}


export const ourSub = (num1 = 0, num2 = 0) => {

  // Definir o número máximo de casas decimais
  const maxDecimals = Math.max(countDecimals(num1), countDecimals(num2));

  // Multiplicar os números pelo fator adequado
  const multiplier = 10 ** maxDecimals;

  // Multiplicar e somar os números
  const result = (num1 * multiplier - num2 * multiplier) / multiplier;

  return decimalWithoutRounding2(result, 9);
}

// Função para contar casas decimais
export const countDecimals = (num) => {
  const decimalPart = String(num).split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

// Função para remover arredondamento
export const decimalWithoutRounding2 = (num, decimalPlaces) => {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(num * multiplier) / multiplier;
}

export const getBase64ImageFromUrl2 = async (url) => {
  return new Promise(function (resolve) {

      var request = require('request').defaults({ encoding: null });

      request.get(url, function (error, response, body) {
          if (!error && response.statusCode == 200) {
              let data = "data:" + response.headers["content-type"] + ";base64," + Buffer.from(body).toString('base64');
              resolve(data);
          }
      });
  });
}

export const capitalizeWordsWithThreshold = (sentence, threshold = 2) => {
  const words = sentence.split(' ');
  const capitalizedWords = words.map((word, index) => {
    // Check if the word has more than the specified threshold letters
    if (word.length > threshold) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else if ( index === 0 ) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word;
    }
  });

  const capitalizedSentence = capitalizedWords.join(' ');

  return capitalizedSentence;
}
